@font-face {
	font-family: Arvo;
	src: local("ArvoRegular"), url("./fonts/Arvo-Regular.ttf") format("truetype");
}

@font-face {
	font-family: Arvo;
	font-weight: bold;
	src: local("ArvoBold"), url("./fonts/Arvo-Bold.ttf") format("truetype");
}

@font-face {
	font-family: Raleway;
	src: local("RalewayRegular"), url("./fonts/Raleway-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Raleway', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
