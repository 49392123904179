.App {
	padding: 5vw;
  background-color: #282c34;
  min-height: 100vh;
  color: white;
  font-size: calc(10px + 1.6vmin);
  font-family: Raleway;
}

.App p {
	margin-block-start: 1.6em;
	margin-block-end: 1.6em;
}

.App a {
  color: #61dafb;
}

.App-logo {
  height: 100px;
}


/* NAV */

.top-nav {
	margin: 0 0.2em;
}


/* MAIN AREA */

.main {
	margin: 3vw;
	
	animation-name: main-animation;
	animation-duration: 0.6s;
	animation-timing-function: ease-in-out;
}

@keyframes main-animation {
	from { opacity: 0; padding-top: 5vh; }
	to { opacity: 1; padding-top: 0; }
}

.page-title {
	font-family: Arvo;
	font-size: 180%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}